import React from 'react';
import StatisticsSection from '../../components/statisticsSection/StatisticsSection';
import bossImage from '../../static/kazim-zer.jpg'

import styles from './InstitutionalPage.module.scss';

const InstitutionalPage = () => {

    return (
        <div className={styles.institutionalPageContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.backgroundBlack}></div>
                <div className={styles.headerMainContainer}>
                    <h1>Kurumsal</h1>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.topContainer}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>HAKKIMIZDA</h1>
                            <p>Zerplast, İstanbul'un kalbinde, 3000 metrekarelik geniş ve modern üretim tesisimizde, banyo ev gereçleri ve temizlik ürünleri konusunda öncü bir üretici olarak hizmet veriyoruz. İhtiyacınız olan kalite, güven ve yenilikçi tasarımlar için doğru adrestesiniz.</p>
                            <p>Müşteri Memnuniyeti İlkemiz</p>
                            <p>Zerplast olarak müşteri memnuniyetini en üst düzeyde tutuyoruz. Siz değerli müşterilerimize özel çözümler sunarak, ihtiyaçlarınıza uygun ürünler geliştiriyoruz. Esnek üretim kapasitemiz ve hızlı teslimat ağımız sayesinde, her an sizin yanınızda olmaktan mutluluk duyuyoruz.</p>
                            <p>Sizleri Aramızda Görmek İstiyoruz!</p>
                            <p>Zerplast ailesi olarak, kalite, güven ve müşteri memnuniyeti ile dolu bir alışveriş deneyimi yaşamanızı bekliyoruz. Modern tesisimizi ziyaret ederek üretim süreçlerimizi yakından görebilir, banyo ev gereçleri ve temizlik ürünlerindeki kaliteyi kendi gözlerinizle deneyimleyebilirsiniz. Bizimle birlikte, temizlik ve güzellik her zaman yanınızda!</p>
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>GRUP ŞİRKETLERİ</h1>
                            <h4 style={{marginTop: "12px"}}>ZERSAN LÜKS HIRDAVAT KIRTASİYE PLASTİK SANAYİ VE DIŞ TİCARET LİMİTED ŞİRKETİ</h4>
                            <p>Bünyesinde bulunan; lüks hırdavat, plastik banyo ve mutfak ürünleri, oyuncak, kırtasiye malzemeleri, ev ve mutfak gereçleri, temizlik malzemeleri, elektrik ve elektronik ürünler, kozmetik, hediyelik eşya, aydınlatma gibi ürünlerin alımını, ithalini, ihracını ve ticaretini yapmaktadır. Bünyesinde yer alan markalarıyla oluşturduğu ürünleri yurtiçinde tüketici ile buluşmasını sağlıyor.</p>
                            <h4 style={{marginTop: "16px"}}>ZERAS OTOMOTİV LÜKS HIRDAVAT SANAYİ DIŞ TİCARET LİMİTED ŞİRKETİ</h4>
                            <p>Bünyesinde bulunan; lüks hırdavat, plastik banyo ve mutfak ürünleri, oyuncak, kırtasiye malzemeleri, ev ve mutfak gereçleri, temizlik malzemeleri, elektrik ve elektronik ürünler, kozmetik, hediyelik eşya, aydınlatma gibi ürünlerin alımını, ithalini, ihracını ve ticaretini yapmaktadır. Bünyesinde yer alan markalarıyla oluşturduğu ürünleri yurtiçinde tüketici ile buluşmasını sağlıyor.</p>
                            <h4 style={{marginTop: "16px"}}>ZERPLAST PLASTİK EV GEREÇLERİ SANAYİ VE TİCARET ANONİM ŞİRKETİ</h4>
                            <p>Zerplast Plastik, 3000 m² kapalı alanda ileri teknoloji makine parkuru ile plastik banyo, ev gereçleri, temizlik ürünleri üretimi yapmaktadır. Yurtiçi ve yurtdışı pazarlarda yaygın bulunurluk sağlamak, üretimde kalite ve hizmette devamlılık sağlamak, müşterileri, iş ortakları ve çalışanları için katma değer yaratmak ilkeleriyle çalışmalarına devam etmektedir.</p>
                            <h4 style={{marginTop: "16px"}}>ZERAY GROUP LLC LIMITED LIABILITY COMPANY</h4>
                            <p>Yurtdışında faliyet gösteren şirketimiz yeme-içme ve restaurant hizmetleri vermektedir.</p>
                        </div>
                        
                    </div>
                    <div className={styles.bottomContainer}>
                    <div style={{ width: "100%", height: "100%" }}>
                            <img  style={{ width: "100%", height: "100%" }} src={bossImage} alt="" />
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>ÜLKEMİZ İÇİN HERZAMAN İLERİ...</h1>
                            <p>Bizler, kaliteli üretim ve müşteri memnuniyeti konusundaki taahhüdümüzü sizlere en iyi şekilde yansıtmak için var gücümüzle çalışıyoruz.</p>
                            <p>Sizin için en iyiyi sunma misyonumuzun temelinde güven yatmaktadır. Fabrikamız, başlangıcından itibaren müşterilerimize olan güveni en üst seviyede tutmayı ilke edinmiştir. Bu güveni sağlamak için kalite, memnuniyet, saygı ve inovasyon misyonlarına bağlı çalışmalarımızı var gücümüzle sürdürüyoruz.</p>
                            <p>Bizimle çalıştığınızda, güvenilir bir iş ortağı bulduğunuzdan emin olabilirsiniz. Müşteri memnuniyeti ve kalite standartlarımızla, sizlere en iyi hizmeti sunmak için buradayız.</p>
                            <p>Sizlere destek olmaktan ve iş birliği yapmaktan mutluluk duyarız.</p>
                            <p>Saygılarımla,</p>
                            <h5>KAZIM ZER</h5>
                            <p>Kurucu</p>
                        </div>
                    </div>

                </div>
            </div>
            <StatisticsSection />
        </div>
    )
}

export default InstitutionalPage;