import React from 'react';
import logo from '../../static/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { LocationIcon, MailIcon, PhoneIcon } from '../../utils/IconUtils';

import styles from './Footer.module.scss';

const Footer = () => {

    const navigate = useNavigate();

    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerMainContainer}>
                <div className={styles.logoContainer}>
                    <img style={{ width: "100%", height: "100%" }} src={logo} alt='' />
                </div>
                <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>MENULER</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <ul>
                            <li onClick={() => navigate("/")}>ANASAYFA</li>
                            <li onClick={() => navigate("/kurumsal")}>KURUMSAL</li>
                            <li>
                                <a href='https://www.zerplast.com.tr/katalog/2024-urun-katalogu' target="_blank" rel='noreferrer'>ÜRÜNLER</a>
                            </li>
                            <li onClick={() => navigate("/uretim")}>ÜRETİM</li>
                            <li onClick={() => navigate("/iletisim")}>İLETİŞİM</li>
                        </ul>
                    </div>
                </div>
                {/* <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>KURUMSAL</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <ul>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                        </ul>
                    </div>
                </div> */}
                <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>İLETİŞİM</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <div className={styles.contactItem}>
                            <div>
                                <LocationIcon style={{ width: "16px", height: "16px" }} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"#"}>
                                    Hadımköy, Dr. Mithat Martı Caddesi No:22 34555 Arnavutköy/İstanbul
                                </Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "12px" }} className={styles.contactItem}>
                            <div>
                                <PhoneIcon style={{ width: "16px", height: "16px" }} color={"#000000"} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"tel:+902128868545"}>
                                    +90 212 886 85 45
                                </Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "12px" }} className={styles.contactItem}>
                            <div>
                                <MailIcon style={{ width: "16px", height: "16px" }} color={"#000000"} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"mailto:info@zerplast.com.tr"}>
                                    info@zerplast.com.tr
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer;