import React from 'react';
import Slider from 'react-slick';
import featureIcon1 from '../../static/feature-box-icon.png';
import featureIcon2 from '../../static/co2.png';
import featureIcon3 from '../../static/surdurulebilir.png';
import featureIcon4 from '../../static/teknoloji.png';
import FeatureBox from '../../components/featureBox/FeatureBox';
import { ArrowRightIcon, PlayIcon } from '../../utils/IconUtils';
import ProductGroupBox from '../../components/productGroupBox/ProductGroupBox';
import StatisticsSection from '../../components/statisticsSection/StatisticsSection';
import { useNavigate } from 'react-router-dom';
import productGroupImage1 from '../../static/slider1.jpg';
import productGroupImage2 from '../../static/slider2.jpg';
import productGroupImage3 from '../../static/slider3.jpg';
import productGroupImage4 from '../../static/slider4.jpg';
import productGroupImage5 from '../../static/slider5.jpg';

import styles from './HomePage.module.scss';

const HomePage = () => {

    const navigate = useNavigate();

    const sliderItems = [
        {
            id: 1,
            url: productGroupImage1
        },
        {
            id: 2,
            url: productGroupImage2
        },
        {
            id: 3,
            url: productGroupImage3
        },
    ]

    const featureBoxData = [
        {
            id: 0,
            title: "SORUMLU ÇEVRE",
            content: "Hammadddelerin Ekonomik Döngüye Kazandırılması",
            img: featureIcon1
        },
        {
            id: 1,
            title: "DAHA AZ ATIK",
            content: "Üretim Yöntemlerimiz ile Daha Az Plastik Kullanımı Sağlanır",
            img: featureIcon2
        },
        {
            id: 2,
            title: "SÜRDÜRÜLEBİLİR ÜRÜN",
            content: "Sürdürülebilir Ürünler Sayesinde Sağlıklı Çevre Faktörü",
            img: featureIcon3
        },
        {
            id: 3,
            title: "YÜKSEK TEKNOLOJİ",
            content: "Endüstri 4.0 Takipçisi Son Teknoloji Tesislerimiz Mevcuttur",
            img: featureIcon4
        },
    ]

    const productGroupData = [
        {
            id: 0,
            title: "TEMİZLİK FIRÇALARI",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum dolor sit quae in officia incidunt maiores, nulla autem quae in officia incidunt maiores, nulla autem.",
            bgColor: "#909c0b",
            hoverColor: "#c1d100",
            img: productGroupImage1,
            url: 'https://www.zerplast.com.tr/katalog/2024-urun-katalogu'
        },
        {
            id: 1,
            title: "YERSİL ÇEKPASLAR",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum dolor sit quae in officia incidunt maiores, nulla autem.",
            bgColor: "#244c5a",
            hoverColor: "#377F99",
            img: productGroupImage2,
            url: 'https://www.zerplast.com.tr/katalog/2024-urun-katalogu'
        },
        {
            id: 2,
            title: "CAMSİL ÇEKÇEKLER",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum.",
            bgColor: "#909c0b",
            hoverColor: "#c1d100",
            img: productGroupImage3,
            url: 'https://www.zerplast.com.tr/katalog/2024-urun-katalogu'
        },
        {
            id: 3,
            title: "SÜPÜRGELİ FARAŞLAR",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem.",
            bgColor: "#244c5a",
            hoverColor: "#377F99",
            img: productGroupImage4,
            url: 'https://www.zerplast.com.tr/katalog/2024-urun-katalogu'
        },
        {
            id: 4,
            title: "TEMİZLİK KOVALARI",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat",
            bgColor: "#909c0b",
            hoverColor: "#377F99",
            img: productGroupImage5,
            url: 'https://www.zerplast.com.tr/katalog/2024-urun-katalogu'
        },
    ]

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        arrows: false,
    };

    return (
        <div className={styles.homePageContainer}>
            <div className={styles.sliderContainer}>
                <Slider {...settings}>
                    {
                        sliderItems.map((val) => (
                            <div key={val.id} className={styles.imageContainer}>
                                <img src={val.url} alt="" />
                            </div>
                        ))
                    }
                </Slider>
            </div>
            <div className={styles.featureContainer}>
                <div className={styles.featureMainContainer}>
                    <div className={styles.featureTitleContainer}>
                        <span><strong>Geleceğe Düşünüyor,</strong> Doğayı Koruyoruz...</span>
                    </div>
                    <div className={styles.boxesContainer}>
                        {
                            featureBoxData.map((val) => (
                                <FeatureBox id={val.id} title={val.title} content={val.content} image={val.img} />
                            ))
                        }
                        <div className={styles.videoBoxContainer}>
                            <div className={styles.imageContainer}>
                                <PlayIcon />
                            </div>
                            <div className={styles.textContainer}>
                                <span>TANITIM FİLMİNİ</span>
                                <span>İZLEMEK İÇİN</span>
                                <span>TIKLAYIN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.aboutContainer}>
                <div className={styles.leftContainer}>
                    <img style={{ width: "100%", height: "100%" }} src="https://picsum.photos/950/552" alt="" />
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.mainContainer}>
                        <h2>Hakkımızda</h2>
                        <p>
                            Zerplast, İstanbul'un kalbinde, 3000 metrekarelik geniş ve modern üretim tesisimizde, banyo ev gereçleri ve temizlik ürünleri konusunda öncü bir üretici olarak hizmet veriyoruz. İhtiyacınız olan kalite, güven ve yenilikçi tasarımlar için doğru adrestesiniz.
                        </p>
                        <p>
                            Müşteri Memnuniyeti İlkemiz
                        </p>
                        <p>
                            Zerplast olarak müşteri memnuniyetini en üst düzeyde tutuyoruz. Siz değerli müşterilerimize özel çözümler sunarak, ihtiyaçlarınıza uygun ürünler geliştiriyoruz. Esnek üretim kapasitemiz ve hızlı teslimat ağımız sayesinde, her an sizin yanınızda olmaktan mutluluk duyuyoruz.
                        </p>
                        <button onClick={() => navigate('/kurumsal')}>
                            <span>Devamını Oku</span>
                            <ArrowRightIcon color={"#c1d100"} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.productGroupsContainer}>
                <div className={styles.productGroupsMainContainer}>
                    <div className={styles.productGroupsTitleContainer}>
                        <span><strong>Ürün</strong> Gruplarımız</span>
                    </div>
                    <div className={styles.productGroupBoxes}>
                        {
                            productGroupData.map((val) => (
                                <ProductGroupBox 
                                    key={val.id}
                                    title={val.title} 
                                    content={val.content} 
                                    bgColor={val.bgColor} 
                                    hoverColor={val.hoverColor} 
                                    image={val.img}
                                    url={val.url}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div className={styles.qualityContainer}>
                <div className={styles.qualityMainContainer}>
                    <div className={styles.leftContainer}>
                        <h1>Kalite Belgelerimiz</h1>
                        <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi debitis illo quasi qui mollitia earum officia velit voluptatum unde, veniam tempore rem soluta iusto nulla, sequi nobis, quia recusandae accusantium.</span>
                        <ul>
                            <li>Hafif</li>
                            <li>Verimi Yüksek</li>
                            <li>Ürünü Korur</li>
                            <li>Gıdaya Uygun</li>
                        </ul>
                        <div className={styles.buttonContainer}>
                            <button>
                                <span>Sertifikalarımız</span>
                                <ArrowRightIcon color={"#c1d100"} />
                            </button>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.imageContainer}>
                            <img src="https://picsum.photos/465/465" alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
            <StatisticsSection />
        </div>
    )
}

export default HomePage