import React, { useState } from 'react';
import { ArrowRightLongIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './ProductGroupBox.module.scss';

const ProductGroupBox = ({ title, content, bgColor, hoverColor, image, url }) => {

    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <a 
            href={url}
            target="_blank"
            rel='noreferrer'
            style={{
                backgroundColor: isHovered ? hoverColor : bgColor
            }}
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)} 
            className={styles.productGroupBoxContainer}
        >
            <div className={styles.textContainer}>
                <h1>{title}</h1>
                <p>{content}</p>
                <ArrowRightLongIcon color={"#fff"} style={{ width: "36px", height: "36px", marginTop: "20px" }} />
            </div>
            <div className={styles.imageContainer}>
                <img src={image} alt="" />
            </div>
        </a>
    )
}

export default ProductGroupBox