import React from 'react';

import styles from './ContactPage.module.scss';
import { LocationIcon } from '../../utils/IconUtils';

const ContactPage = () => {
    return (
        <div className={styles.contactPageContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.backgroundBlack}></div>
                <div className={styles.headerMainContainer}>
                    <h1>İletişim</h1>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoTitleContainer}>
                            <h1>İletişim Bilgilerimiz</h1>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>Adres</h2>
                                <p>Hadımköy, Dr. Mithat Martı Caddesi No:22 34555 Arnavutköy/İstanbul</p>
                            </div>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>TELEFON</h2>
                                <p>+90 212 886 85 45</p>
                            </div>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>E-POSTA</h2>
                                <p>info@zerplast.com.tr</p>
                                <p>export@zerpak.com.tr</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactFormContainer}>
                        <div className={styles.formTitleContainer}>
                            <h1>Hızlı İletişim Formu</h1>
                        </div>
                        <div className={styles.inputContainer}>
                            <input type="text" placeholder='Adınız ve Soyadınız' />
                            <input type="text" placeholder='E-Mail Adresiniz' />
                        </div>
                        <div className={styles.inputContainer}>
                            <input type="text" placeholder='Konu' />
                        </div>
                        <div className={styles.inputContainer}>
                            <textarea id="w3review" name="w3review" rows="8" placeholder='Mesajınız' />
                        </div>
                        <div className={styles.buttonContainer}>
                            <button>
                                <span>Gönder</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6009.232003046906!2d28.638059!3d41.142908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b5579744c7e86d%3A0x3b0616ea02cfbd07!2zSGFkxLFta8O2eSwgRHIuIE1pdGhhdCBNYXJ0xLEgQ2QuIE5vOjIyLCAzNDU1NSBBcm5hdnV0a8O2eS_EsHN0YW5idWwsIFTDvHJraXll!5e0!3m2!1str!2sus!4v1706120392002!5m2!1str!2sus" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default ContactPage